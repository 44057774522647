import React, { memo, ReactChild } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Stylable, WithChildren } from "../../types/reactTypes"
import classNames from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import styled from "@emotion/styled"

export interface CardProps extends WithChildren, Stylable {
  title?: string | ReactChild
  icon?: IconProp
  header?: any
  loading?: boolean
  inline?: boolean
}

export function CardHeader(props: CardProps) {
  if (!props.title) return null
  return (
    <header className="card-header">
      <p className="card-header-title">
        <span className="icon">
          <FontAwesomeIcon icon={props.icon} />
        </span>
        <span>{props.title}</span>
      </p>
      {props.header}
    </header>
  )
}

export const CardHeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`

export const CardContent = (props) => (
  <div className="card-content">{props.children}</div>
)

function Card(props: CardProps) {
  return (
    <StyledCard
      className={classNames("card", props.className, {
        "is-loading": props.loading,
      })}
      inline={props.inline}
    >
      <CardHeader {...props} />
      {props.children}
    </StyledCard>
  )
}

const StyledCard = styled.div<{ inline: boolean }>`
  display: ${(props) => props.inline && "inline-block"};
  overflow: visible;
  .card-header-title .icon {
    margin-right: 8px;
  }
  &.is-loading {
    pointer-events: none;
    opacity: 0.5;
  }
`

export default memo(Card)
