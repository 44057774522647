import { useQuery } from "react-query"
import { LivestreamTypeExternal } from "@core/types/domain/Livestream"
import { AUTH_HEADER, fetchApi, requireOk, toJson } from "~/services/fetchApi"
import { parseExternalStream } from "~/screens/external/stream/useExternalStream"

export function useExternalLivestreams(projectId: number, code: string) {
  return useQuery(
    ["livestreamsExternal", projectId, code],
    () => fetchExternalLivestreams(projectId, code),
    {
      enabled: !!projectId,
    }
  )
}

function fetchExternalLivestreams(
  projectId: number,
  code: string
): Promise<LivestreamTypeExternal[]> {
  return fetchApi(`/livestream/external?projectIds=${projectId}`, {
    headers: {
      [AUTH_HEADER]: `Code ${code}`,
    },
  })
    .then(requireOk)
    .then(toJson)
    .then(parseResult)
}

function parseResult(
  results: LivestreamTypeExternal<string>[]
): LivestreamTypeExternal[] {
  return results?.map(parseExternalStream)
}
